<template>
  <b-button
    v-b-tooltip.hover="toolTipText"
    v-ripple.400="'rgba(225,225,225,0.15)'"
    :variant="`outline-${variant}`"
    :disabled="disabled || loading"
    :class="btnClass"
    :size="size"
    @click="handleClick"
  >
    <div v-if="loading">
      <b-spinner
        small
        type="grow"
        class="mr-1"
      />
      <span>{{ loadingText }}</span>
    </div>
    <span
        class="align-middle"
      >{{ label }}</span>
    <!-- <div v-else>
      <feather-icon v-if="!iconOnly && icon"
        :icon="icon"
        style="margin-right: 4px"
      />
      <span
        v-if="!iconOnly"
        class="align-middle"
      >{{ label }}</span>
    </div> -->

  </b-button>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import { BButton, BSpinner, VBTooltip } from 'bootstrap-vue'

export default {
  name: 'HwaButton',
  components: {
    BButton,
    BSpinner,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    label: { type: String, default: 'Button' },
    icon: { type: String, default: 'CheckCircleIcon' },
    variant: { type: String, default: 'primary' },
    btnClass: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    iconOnly: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    loadingText: { type: String, default: 'Loading...' },
    toolTipText: { type: String, default: '' },
    size: { type: String, default: null },
  },
  methods: {
    handleClick() {
      this.$emit('onClick')
    },
  },
}
</script>

<style scoped>

</style>
